// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-income-report-js": () => import("/opt/build/repo/src/templates/income-report.js" /* webpackChunkName: "component---src-templates-income-report-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-income-reports-js": () => import("/opt/build/repo/src/pages/income-reports.js" /* webpackChunkName: "component---src-pages-income-reports-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recommended-tools-js": () => import("/opt/build/repo/src/pages/recommended-tools.js" /* webpackChunkName: "component---src-pages-recommended-tools-js" */),
  "component---src-pages-tutorials-how-to-make-a-wordpress-website-js": () => import("/opt/build/repo/src/pages/tutorials/how-to-make-a-wordpress-website.js" /* webpackChunkName: "component---src-pages-tutorials-how-to-make-a-wordpress-website-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

